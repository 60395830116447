<template>
  <BlogPost
    v-if="article"
    data-test-id="blogHeroArticle"
    :article="{
      title: article.title,
      category: article.category,
      image: getSrcOf(article.image),
      date: formatArticleDate(article.date),
      link: getArticleLink(article),
      meta_description: article.meta_description,
      readingTime: article.readingTime,
      isHero: true,
      linkComponent: NuxtLink,
    }"
  />
</template>

<script setup lang="ts">
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useContentHelpers } from "../../composables/UseContent/helpers";
import { formatArticleDate, getArticleLink } from "../../composables/UseContent/useArticleList";
const NuxtLink = resolveComponent("NuxtLink");

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const { getSrcOf } = useContentHelpers(page.value, component.value);

const article = computed(() => (page.value.getContent(component.value.getModels().article) as any).getData());
</script>
